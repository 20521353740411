import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export interface SeoStructureProps {
  children: React.JSX.Element | React.JSX.Element[];
  title: string;
  description?: string;
  slug: string;
  uri?: string
}

const SeoStructure = ({
  children,
  title,
  description,
  slug,
  uri
}: SeoStructureProps): React.JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
        id
      }
      wp {
        seo {
          social {
            twitter {
              cardType
              username
            }
          }
        }
        generalSettings {
          description
          title
        }
      }
    }
  `);

  const seoUrl = `${data.site.siteMetadata.siteUrl}${
    slug === "/" ? "" : "/" + slug
  }`;
  const twitter = data.wp.seo?.social?.twitter;
  const wpGeneralSettings = data.wp.generalSettings;
  const siteName = data.site.siteMetadata.title;
  const fullUri = uri ? data.site.siteMetadata.siteUrl + uri : seoUrl;
  
  return (
    <>
      <title>
        {title} - {siteName}
      </title>
      <meta
        name="description"
        content={description || wpGeneralSettings?.description}
      />
      <link rel="canonical" href={fullUri} />
      <meta name="og:title" content={title} />
      <meta
        name="og:description"
        content={description || data.site.siteMetadata.description}
      />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={fullUri} />
      <meta name="og:site_name" content={wpGeneralSettings?.title} />
      {twitter?.cardType && (
        <meta name="twitter:card" content={twitter.cardType} />
      )}
      {twitter?.username && (
        <meta name="twitter:site" content={twitter.username} />
      )}
      {children}
    </>
  );
};

export default SeoStructure;